@import "colors.scss";


.App{
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  // background-color:  $nav-color;  
  // color: #242424;
}

.section{
  width: 100%;
  height: 100%;
  .sectionTitle{
    color: #fafafa;
  }
}

.section > div{
    margin-left: 10%;
    margin-right: 10%;
}

#home{
  background-color: $nav-color;
}

#experience{
  background-color: $nav-dark;
}

#projects{
  background-color: $nav-color;
}

#contact{
  background-color: $nav-dark;
  padding-bottom: 40px;
}

.introWrapper{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.intro{
  color: #fafafa;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-right: 20%;
  @media only screen and (max-width: 740px) {
    padding-right: 0%;
    text-align: center;
  }
  .introTldr{
    font-size: 19px;
    @media only screen and (max-width: 740px) {
      margin-right: 5%;
      margin-left: 5%;
    }
  }
}

.arrow{
  @media only screen and (max-width: 740px) {
   display: none;
  }
  position: relative;
  fill: black;
  background-color: transparent;
  width: 500px;
  padding-top: 3%;
}

.experienceGrid{
  display: grid;
  gap: 10px;

  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

  // grid-template-columns: repeat(4, 1fr);
  // @media only screen and (max-width: 1480px) {
  //   grid-template-columns: repeat(2, 1fr);
  // }
  // @media only screen and (max-width: 740px) {
  //   grid-template-columns: repeat(1, 1fr);
  // }
}

.projectGrid{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  @media only screen and (max-width: 1480px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media only screen and (max-width: 740px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.footer{
  height: 5vh;
  width: 100%;
  background-color: $nav-dark;
  color: $accent;
  box-shadow: 0px -2px 6px #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
}