@import "../../../colors.scss";

.cardBox{
    min-height: 300px;
    min-width: 300px;
    background-color: #27272a;
    border-radius: 4px;
    color: #b9b9c0; 
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    box-shadow: 2px 3px 8px #000000;
    
}

.cardText{
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    height: 50%;
}

.cardTitle{
    font-weight: bold;
    color: #fafafa
}

.cardTldr{
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: normal;
}

.cardDescription{
    font-size: 13px;
    font-weight: normal;
}

.languagesWrapper{
    padding-left: 10px;
    padding-right: 10px;
    height: 5%;
    .cardLanguages{
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
        color: #d4d4d8;
        div{
            background-color: #3f3f46;
            padding-right: 5px;
            padding-left: 5px;
            font-size: 13px;
        }
    }
}

.bottomBar{
    color: #d4d4d8;
    width: 100%;
    height: 40px;
    background-color: $accent;
    border-radius:0 0 4px 4px;
    display: flex;
    div{
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        cursor: pointer;
        &:hover{
            background-color: $accent-highlight;
            border-radius:0 0 4px 4px;
        }
    }

}

