@import "../../../colors.scss";

.navBar {
  background-color: $nav-color;
  box-shadow: 0px 1px 4px #000000;
}
.navBrand {
  padding-left: 10px;
}

.resume{
  padding-right: 200px;
}

.navLink {
  // color: $text-accent !important;
}
