@import "../../../colors.scss";

.formContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    color: #fafafa;
    input{
        width: 100%;
        border-radius: 4px;
        background-color: #fafafa;
        text-align: left;
        padding: 5px;
        border: none;
    }
    textarea:focus, input:focus{
        outline: none;
    }
    button{
        margin-top: 5px;
        width: 100%;
        height: 30px;
        border: none;
        border-radius: 4px;
        background-color: $accent;
        color: #fafafa;
        &:hover{
            background-color: $accent-highlight;
        }
        &:disabled{
            background-color: $accent;
        }
    }

    #name, #email, #message{
        border-radius: 4px;
        border: none;
    }

    .messageStatus{
        width: 100%;
        height: 10px;
        text-align: center;
        .messageStatusSuccess{
            background-color: #3aa843;
            border-radius: 4px;
        }
        .messageStatusFailure{
            background-color: #ff2121;
            border-radius: 4px;
        }
    }
   
}